.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #1c1c1c;
  padding: 1.5rem;
  width: 80%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.modal-header {
  display: flex;
  width: 100%;
}

.modal-title {
  font-size: 2.5em;
  margin-top: -0.125em;
  flex: 1;
}

.points-modal-content {
  display: flex;
  flex-direction: column;
  gap: 2em;
  align-items: center;
}

.modal-description {
  color: rgb(184, 184, 184);
  font-size: 1.25em;
  text-align: justify;
}

.modal-close-btn {
  cursor: pointer;
}

.modal-close-btn > img {
  image-rendering: pixelated;
  width: 24px;
  height: 24px;
}

.duck-row {
  display: flex;
  column-gap: 0.25rem;
}

.points-table {
  display: flex;
  flex-direction: column;
}

.points-table-header {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: rgba(192, 192, 30, 0.887);
}

.points-table-row,
.multiplier-table-row {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.5rem 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.points-table-row {
  font-size: 1.5rem;
}

.multiplier-table-row {
  font-size: 1.25rem;
  gap: 1rem;
}
/* 
.points-table-row > span {
  color: rgba(243, 243, 0, 0.887);
} */

.points-duck-img {
  width: 30px;
  height: 30px;
  image-rendering: pixelated;
}

.tables {
  display: flex;
  gap: 2em;
  justify-content: space-between;
  width: 100%;
}
