.Reel {
  width: 176px;
  perspective: 17.5cm;
}

.Reel > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 176px;
  height: 176px;
  backface-visibility: hidden;
}

.Active-reel {
  cursor: pointer;
}

.Active-reel:hover {
  transform: scale(1.1) !important;
}

.Combo-reel {
  animation-name: combo-reel;
  animation-duration: 0.75s;
  animation-timing-function: linear;
  animation-iteration-count: 3;
  animation-fill-mode: forwards;
}

@keyframes combo-reel {
  0% {
    transform: scale(1);
  }
  49.99% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  99.99% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
