.App {
  text-align: center;
  background-color: rgb(45, 45, 45);
  min-height: calc(100vh - 4em);
  color: rgb(222, 222, 222);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: white;

  padding: 2em 0;
}

.App-header {
  display: flex;
  flex-direction: column;
}

.Title {
  font-size: 5em;
}

.Points {
  font-size: 3em;
  margin-top: -0.25em;
  margin-right: 0.25em;
  color: rgba(192, 192, 30, 0.887);

  display: flex;
  justify-content: center;
  gap: 0.25em;
}

.App-reels {
  display: flex;
  flex-direction: row;
  justify-content: center;

  gap: 1.25em;

  /* TODO; fix */
  margin-top: -10em;
}

.Roll-button {
  border: 4px solid rgb(222, 222, 222);
  background-color: rgba(255, 255, 255, 0.2);
  color: rgb(222, 222, 222);
  font-family: "ArcadeClassic";
  width: 6em;
  height: 1.5em;
  font-size: 3em;
}

.Roll-button-clickable {
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
}

.Roll-button-clickable:active {
  transform: translateY(4px);
}
