.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #1c1c1c;
  padding: 1.5rem;
  width: 80%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.modal-header {
  display: flex;
  width: 100%;
}

.modal-title {
  font-size: 2.5em;
  margin-top: -0.125em;
  flex: 1;
}

.modal-content {
  display: flex;
  gap: 2em;
  align-items: center;
}

.modal-description {
  color: rgb(184, 184, 184);
  font-size: 1.25em;
  text-align: justify;
}

.modal-close-btn {
  cursor: pointer;
}

.modal-close-btn > img {
  image-rendering: pixelated;
  width: 24px;
  height: 24px;
}

@keyframes duck-float {
  0%,
  49.99% {
    transform: translateY(-3px);
  }
  50%,
  100% {
    transform: translateY(3px);
  }
}

.duck-container {
  animation: duck-float 2s steps(1) infinite;
}

.duck-img {
  image-rendering: pixelated;
  width: 200px;
  height: 200px;
}
